module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-141812096-2","G-4NP7CSBP4Z"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NS6XWMV","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SNSキャンペーンツール","short_name":"SNSキャンペーンツール｜リタッチ","description":"キャンペーン・インスタントウィンやUGCの応募から抽選までを一括管理できるキャンペーンツールです。Twitter,Instagram,LINE,Facebook,Apple Music,Spotify,Twitch,ツイキャス,写真投稿,くじ引き,クーポン配布など、60以上の応募方法","start_url":"/","background_color":"#ffffff","theme_color":"#ff5d22","display":"standalone","icon":"src/assets/images/favicons/icon-16x16.png","icons":[{"src":"src/assets/images/favicons/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-128x128.png","sizes":"128x128","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c8e5af83db3dddd061a62d3f6e7cf930"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":85,"icon":false,"maintainCase":false,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"quality":100,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://retach.biz"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
