// all styles here
import "./src/assets/css/bootstrap.min.css"
import "./src/assets/css/animate.min.css"
import "./src/assets/css/boxicons.min.css"
import "./src/assets/css/flaticon.css"
import './node_modules/react-modal-video/css/modal-video.min.css'
import 'react-accessible-accordion/dist/fancy-example.css'
import 'react-image-lightbox/style.css'
import "./src/assets/css/style.css"
import "./src/assets/css/responsive.css"
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";


const getTargetOffset = function getTargetOffset(hash) {
  const id = window.decodeURI(hash.replace("#", ""));

  if (id !== "") {
	const element = document.getElementById(id);
	if (element) {
	  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
	  const clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
	  const computedStyles = window.getComputedStyle(element);
	  const scrollMarginTop = computedStyles.getPropertyValue("scroll-margin-top") || computedStyles.getPropertyValue("scroll-snap-margin-top") || "0px";
	  const mainNavHeight = document.querySelector(`#navbar`).offsetHeight;
	  return element.getBoundingClientRect().top + scrollTop - parseInt(scrollMarginTop, 10) - clientTop - mainNavHeight;
	}
  }

  return null;
};
export const onRouteUpdate = ({location}) => {
  anchorScroll(location);
  return true;
};
export const shouldUpdateScroll = ({
								routerProps: { location },
								getSavedScrollPosition
							  }) => {
  anchorScroll(location);
  return true;
}

function anchorScroll(location){
  // Check for location so build does not fail
  if (location && location.hash) {
	setTimeout(() => {
	  const offset = getTargetOffset(location.hash);
	  window.scrollTo({top: offset, left: 0, behavior: 'smooth'});
	}, 0);
  }
}